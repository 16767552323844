<template>
  <form @submit.prevent="doFilter">
    <div class="col-6">
      <OrderStatusMultiselect v-model="filter.statuses" :disabled="disabled"/>
    </div>
    <div class="col-6">
      <CustomerSearch :disabled="disabled" @message="setCustomerSearchMessage" v-model="filter.selectedCustomer" />
      <span v-if="customerSearchMessage" class="text-danger">{{ customerSearchMessage }}</span>
    </div>
    <div class="col-6">
      <CategoriesSearch v-model="filter.selectedCategories" :disabled="disabled" />
    </div>
    <div class="col-6">
      <label for="">انتخاب درگاه</label>
      <multiselect
        placeholder="نوع درگاه"
        v-model="filter.gateway_name"
        open-direction="bottom"
        :options="gateways"
        :searchable="true"
        :close-on-select="true"
        :show-labels="false"
        :disabled="disabled"
      >
        <template slot="noOptions">
          موردی یافت نشد
        </template>
        <template slot="noResult">
          موردی یافت نشد
        </template>
      </multiselect>
    </div>
    <div class="col-12">
      <AreaSearch v-model="filter.area" />
    </div>
    <div class="col-12">
      <DatePickerRange v-model="filter.date" />
    </div>
    <template v-if="optionsLoaded">
      <div class="col-6 mt-4" style="direction: ltr;text-align: left;position: relative">
        <p class="text-right">بازه ی مبلغ سفارش</p>
        <RangeSlider :step="10000" v-model="filter.totalRange" :min="totalRange[0]" :max="totalRange[1]" :enable-cross="true" />
      </div>
      <div class="col-6 mt-4" style="direction: ltr;text-align: left;position: relative">
        <p class="text-right">بازه ی تعداد اقلام سفارش</p>
        <RangeSlider :step="1" v-model="filter.itemsCountRange" :min="itemsCountRange[0]" :max="itemsCountRange[1]" :enable-cross="true" />
      </div>
    </template>
    <div class="col-12">
      <button class="btn btn-primary mt-4" @click="doFilter" :disabled="disabled"><FilterSvg/> فیلتر</button>
    </div>
  </form>

</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import RangeSlider from "@@/core/components/shared/RangeSlider/vue-range-slider"
import OrderStatusMultiselect from "@@/core/components/shared/OrderStatusMultiselect/OrderStatusMultiselect";
import CustomerSearch from "@@/core/components/shared/CustomerSearch/CustomerSearch";
import CategoriesSearch from "@@/core/components/shared/CategoriesSearch/CategoriesSearch";
import AreaSearch from "@@/core/components/shared/AreaSearch/AreaSearch";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import FilterSvg from "@@/core/components/svgs/FilterSvg";

// If you want to add a new filter see orderReportFilterMixin
export default {
  name: "OrderReportFilter",
  components: {FilterSvg, DatePickerRange, AreaSearch, CategoriesSearch, CustomerSearch, OrderStatusMultiselect, RangeSlider},
  props: {
    disabled: Boolean,
    value: Object,
    gateways:Array
  },
  data() {
    return {
      customerSearchMessage: '',
      filter: cloneDeep(this.value),
      totalRange: [0, 100],
      itemsCountRange: [0, 100],
      optionsLoaded: false
    }
  },
  created() {
    this.loadFilterOptions()
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$emit('input', this.filter)
      }
    }
  },
  methods: {
    async loadFilterOptions() {
      try {
        const response = await this.$axios.get('/admin/reports/order_filter_helper');
        const max_items_count = Number.parseInt(response.data.data.max_items_count)
        const max_total = Number.parseInt(response.data.data.max_total)
        this.totalRange[1] = max_total
        this.itemsCountRange[1] = max_items_count
        this.filter.totalRange[1] = max_total
        this.filter.itemsCountRange[1] = max_items_count
        this.optionsLoaded = true
      } catch (e) {
        this.$root.notify(e)
      }

    },
    setCustomerSearchMessage(message) {
      this.customerSearchMessage = message
    },
    doFilter() {
      this.$parent.page = 1
      this.$parent.loadData()
    },
  }
}
</script>

<style scoped>

</style>
