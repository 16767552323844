import Loading from '@@/core/components/shared/Loading/Loading'
import CouponModal from '@@/core/components/coupon/CouponForm/CouponForm'
import {BTooltip, BTable, VBModal,BFormInput} from "bootstrap-vue";

import mixins from '@@/core/mixins/mixins'

export default {
  name: "Coupon",
  components: {
    Loading,
    CouponModal,
    BTooltip, BTable,BFormInput
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      searchList:[],
      search:null,
      url: 'admin/coupon',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'title',
          label: 'عنوان'
        },
        {
          key: 'code',
          label: 'کد'
        },
        {
          key: 'usage_limit',
          label: 'سقف استفاده'
        },
        {
          key: 'usage_per_user_limit',
          label: 'سقف استفاده برای هر کاربر'
        },
        {
          key: 'total_usage',
          label: 'تعداد استفاده'
        },
        {
          key: 'start_date',
          label: 'تاریخ شروع'
        },
        {
          key: 'end_date',
          label: 'تاریخ پایان'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      submit_url: 'admin/coupon',
      mode: 'create',
      coupon: {
        title: '',
        code: '',
        start_date: '',
        end_date: '',
        type: 'percentage',
        amount: 0,
        usage_limit: 0,
        usage_per_user_limit: 0,
        min_order_amount: ''
      },
      select_type: null,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async editItem(id, index) {
      this.disabled = true
      try {
        this.coupon = this.items.data.find((item) => {
          return item.id == id;
        })
        this.mode = 'edit'
        this.submit_url = this.url + `/${id}`
        this.$root.$emit('bv::show::modal', 'coupon-modal')
        this.coupon.index = index
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    newCoupon() {
      this.mode = 'create'
      this.submit_url = this.url
      this.coupon = {
        title: '',
        code: '',
        start_date: '',
        end_date: '',
        type: 'percentage',
        amount: 0,
        usage_limit: 0,
        usage_per_user_limit: 0,
        min_order_amount: ''
      }
    },
    searchRequest() {
      if(this.search==null||this.search==''){
        this.extraQuery=null;
        this.loadItems();
      }else{
        this.extraQuery={keyword:this.search}
        this.loadItems()
      }
    },
  },
  watch:{
    search(newVal){
      // زمانی که کاربر شروع به جستوجو کرد بعد از وارد کردن هر حرف دو ثانیه منتظر می مانیم تا اگر حرفی وارد کرد بیهوده درخواست نزنیم و منتظر کامل شدن عبارت مد نظر آن میشویم
    // تماشا کردن تغییرات اینپوت سرچ و اگر بعد از اخرین حرف وارده حرفی وارد نکند سرچ انجام میشود
    this.searchList.push(newVal);
    let last = this.searchList.length;
    setTimeout(() => {
      if (this.searchList[last] == undefined) {
        this.searchList = [];
        this.searchRequest();
      }
    }, 1500);
    }
  }
}
