<template>
  <main class="mt-5">
    <transition>
      <div
        v-if="loading && mode != 'create'"
        class="d-flex justify-content-center w-100 mt-3"
      >
        <Loading />
      </div>
      <b-row v-else>
        <b-col cols="8">
          <ProductInformation
            @add-tag="addTag"
            :tags="tags"
            :units="units"
            :categories="categories"
            :selected-categories="selectedCategories"
            v-model="productInformation"
          />
          <ProductDescription v-model="productDescription" />
          <ProductImages v-model="productImages" />
          <ProductVarieties
            ref="productVarieties"
            v-model="productVarieties"
            :gifts="gifts"
            :product-information="productInformation"
            :colors="colors"
            :categories="categories"
            :all-attributes="attributes"
            :selected-categories="selectedCategories"
            :default-price="productPricing.unitPrice"
            :mode="mode"
          />
          <ProductSpecifications
            v-model="selectedSpecs"
            :selected-categories="selectedCategories"
            :public-specifications="publicSpecifications"
          />
          <ProductSizeChart
            v-if="$options.configProvider.get('product.hasSizeChart')"
            @add="addSizeChart"
            v-model="productSizeCharts"
            :size-chart-types="sizeChartTypes"
          />
          <ProductSeo v-model="productSeo" />
        </b-col>
        <b-col cols="4">
          <ProductPublishing
            :submit="submit"
            :disabled="disabled"
            v-model="productPublishing"
          />
          <ProductPricing v-model="productPricing" />
          <ProductGifts
            v-if="$options.configProvider.get('product.hasGifts')"
            v-model="productGifts"
            :gifts="gifts"
          />
          <!--          <ProductShippingConfig/>-->
          <ProductShowConfig v-model="productShow" />
          <!--          <ProductFlashConfig/>-->
          <ProductOtherConfig v-model="productOther" />
        </b-col>
        <b-col cols="12">
          <b-button @click="submit" :disabled="disabled" variant="primary"
            >ثبت محصول</b-button
          >
        </b-col>
      </b-row>
    </transition>
  </main>
</template>

<script>
import ProductCreateEditImpl from "@@/core/pages/product/ProductCreateEdit/ProductCreateEditImpl";
import ProductGifts from "@@/core/components/product/small-cards/ProductGifts/ProductGifts";
export default {
  components: { ProductGifts },
  extends: ProductCreateEditImpl,
};
</script>

<style scoped></style>
