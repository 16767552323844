import configProvider from "@@/core/configProvider";

export default {
  data() {
    return {
      selectedMode: configProvider.get('physicalShop.active') ? 'all' : 'online',
    }
  },
  computed: {
    reportTypeOptions() {
      return [{name: 'all', label: 'همه'},
        {name: 'online', label: 'آنلاین'}, {name: 'mini', label: 'حضوری'}]
    },
    reportCode() {
      switch (this.selectedMode) {
        case 'online':
          return 0
        case 'all':
          return 2
        case 'mini':
          return 1
      }
    }
  },
}
