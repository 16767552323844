import {BFormGroup, BFormInput, BFormTextarea, BModal} from "bootstrap-vue";
import ProductSelect from '@@/core/components/shared/ProductSelect/ProductSelect'

export default {
  name: 'StoreForm',
  components: { ProductSelect,BModal, BFormInput, BFormTextarea, BFormGroup },
  props: {
    products: Array,
    mode: {
      default: "increment",
      type: String,
    },
    value: [Object, String],
  },
  data() {
    return {
      disabled: false,
      quantity: 0,
      url: "admin/stores",
      disabledBtn: false,
      varieties: [],
      showVarieties: false,
      store: this.value,
    };
  },
  watch: {
    products(newVal) {
      return newVal.forEach((product, index) => {
        product.index = index;
      });
    },
    store(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.store = newVal;
    },

  },
  methods: {
    async submit() {
      this.disabled = true;
      try {
        let formData = new FormData(this.$refs.form);
        formData.append(
          "variety_id",
          this.store.selectedVariety != null
            ? this.store.selectedVariety.id
            : ""
        );
        formData.append(
          "type",
          this.mode == "increment" ? "increment" : "decrement"
        );
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response.data.message, "success");
        let store = response.data.data.transaction;
        store.store = response.data.data.store;
        this.setStore(response.data.data.store.balance);
        this.$parent._data.items.data.unshift(store);
        this.$root.$emit("bv::hide::modal", "store-modal");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    setStore(balance) {
      this.products[this.store.selectedProduct.index].varieties[
        this.store.selectedVariety.index
      ].quantity = balance;
    },
    selectVariety(v) {
      console.log(this.store)
      this.store.selectedVariety = v
    }
  },
};
