import { getEventLabel, getModelLabel } from "@@/core/libraries/adminFunctions";
import OrderStatus from "@@/core/components/order/OrderStatus/OrderStatus";
import ProductCommentShow from "@@/core/components/product/ProductCommentShow/ProductCommentShow";
import Chart2 from "@@/core/components/dashboard/Chart2/Chart2";
import Chart1 from "@@/core/components/dashboard/Chart1/Chart1";
import Loading from "@@/core/components/shared/Loading/Loading";
import { BFormRating } from "bootstrap-vue";
import { isPermissionError } from "@@/core/libraries/functions";

export default {
  name: "Dashboard",
  components: {
    OrderStatus,
    ProductCommentShow,
    Chart2,
    Chart1,
    Loading,
    BFormRating,
  },
  mixins: [],
  data() {
    return {
      // https://api.elinorboutique.com/v1/admin/dashboards/contacts
      // https://api.elinorboutique.com/v1/admin/dashboards/salesAmountByMonth
      // https://api.elinorboutique.com/v1/admin/dashboards/genderStatistics
      // https://api.elinorboutique.com/v1/admin/dashboards/orderByStatus
      data: {},
      loader: {
        salesAmountByToday: false,
        ordersCount: false,
        todayOrdersCount: false,
        logs: false,
        lastLogins: false,
        siteViews: false,
        genderStatistics: false,
        comments: false,
        lastOrders: false,
        orderByStatus: false,
      },
      flags: {
        chart1: false,
        salesAmountByToday: false,
        ordersCount: false,
        todayOrdersCount: false,
        logs: false,
        lastLogins: false,
        siteViews: false,
        genderStatistics: false,
        comments: false,
        lastOrders: false,
        orderByStatus: false,
      },
      chartsLoaded: false,
      showingProductComment: null,
      notLoading: false,
    };
  },
  watch: {},
  created() {
    if (
      Object.keys(this.$store.getters["admin/dashboard/getData"]).length != 0
    ) {
      this.data = this.$store.getters["admin/dashboard/getData"];
    }
  },
  methods: {
    getModelLabel,
    getEventLabel,

    showAndFetchData(key) {
      if (!this.flags[key]) this.flags[key] = true;
      this.loadData(key);
    },
    async loadData(key, callBackFunc = null) {
      this.loader[key] = true;
      try {
        // handlerLoader
        this.data[key] = await this.$store.dispatch(
          "admin/dashboard/fetchData",
          key
        );
        if (key == "genderStatistics") this.loadCharts();
      } catch (error) {
        if (!isPermissionError(error)) {
          this.$root.notify(error);
        } else {
          this.notLoading = true;
        }
      } finally {
        this.loader[key] = false;
      }
    },
    loadCharts() {
      setTimeout(() => {
        /*----- Employees ------*/
        var options = {
          series: [
            this.data.genderStatistics.gender_statistics.males_count,
            this.data.genderStatistics.gender_statistics.females_count,
            this.data.genderStatistics.gender_statistics.unknowns_count,
          ],
          chart: {
            height: 300,
            type: "donut",
          },
          dataLabels: {
            enabled: false,
          },

          legend: {
            show: false,
          },
          stroke: {
            show: true,
            width: 0,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "80%",
                background: "transparent",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "29px",
                    color: "#6c6f9a",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    fontSize: "26px",
                    color: undefined,
                    offsetY: 16,
                    formatter: function(val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    showAlways: false,
                    label: "تعداد کل",
                    fontSize: "22px",
                    fontWeight: 600,
                    color: "#373d3f",
                    // formatter: function (w) {
                    //   return w.globals.seriesTotals.reduce((a, b) => {
                    // 	return a + b
                    //   }, 0)
                    // }
                  },
                },
              },
            },
          },
          responsive: [
            {
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
          labels: ["مرد", "زن", "انتخاب نشده"],
          colors: ["#3366ff", "#fe7f00", "#ffe53f"],
        };
        // eslint-disable-next-line no-undef
        var chart = new ApexCharts(
          document.querySelector("#employees"),
          options
        );
        chart.render();

        //________ Datepicker
        window.$(".fc-datepicker").datepicker({
          dateFormat: "dd M yy",
          monthNamesShort: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Maj",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec",
          ],
        });

        //________ Timepiocker
        window.$("#tpBasic").timepicker();

        //________ Countdonwtimer
        window.$("#clocktimer").countdowntimer({
          currentTime: true,
          size: "md",
          borderColor: "transparent",
          backgroundColor: "transparent",
          fontColor: "#313e6a",
          //timeZone : -1 //
        });
      }, 0);
    },
    showProductComment(comment) {
      this.showingProductComment = comment;
      this.$nextTick(() => {
        this.$root.$emit("bv::show::modal", "comment-modal");
      });
    },
    onProductCommentStatusChanged(payload) {
      let id = payload.id;
      let status = payload.status;
      this.data.comments.product_comments = this.data.comments.product_comments.map(
        (comment) => {
          if (comment.id == id) {
            comment.status = status;
          }

          return comment;
        }
      );
    },
    getTotalItemsInOrder(order) {
      let total = 0;
      order.items.forEach((item) => (total += item.quantity));
      return total;
    },
  },
};
