import Vue from "vue";
import crudMixin from "@@/core/mixins/crudMixin";
import { isPart } from "@@/core/libraries/VueMethods";

export default {
  mixins: [crudMixin],
  data() {
    return {
      extraQuery:null,
      url: String,
      editItemValue: {},
      edit: {},
      show: false,
      search: false,
      disabled: false,
      price: 0,
      amount: 0,
      search_price: 0,
      searchUrl: "",
      searchParams: {},
      createRules: {},
      editRules: {},
      hasPagination: true,
      mode: "create",
      widgetData: {},
      widgets: [],
      items: {},
      onLoadHandlers: [],
    };
  },
  mounted() {
    this.widgetsAreLoaded = false;

    if (isPart("admin") && this.$root.getCookie("adminAuthorization")) {
      this.$axios.defaults.headers.common[
        "Authorization"
      ] = this.$root.getCookie("adminAuthorization");
    }

    if (this.$data.filter && this.$route.query.search) {
      let parsedSearch = JSON.parse(this.$route.query.search);
      let hasFilter = false;
      for (let i = 0; i < 20; i++) {
        if (parsedSearch["search" + i] && parsedSearch["searchBy" + i]) {
          hasFilter = true;
          this.filter[parsedSearch["searchBy" + i]] =
            parsedSearch["search" + i];
        }
      }
      if (hasFilter) {
        this.$root.$emit("bv::toggle::collapse", "filter");
      }
    }
    // document.title = this.title
    // window.$('body').removeClass('active')
  },
  watch: {
    "$route.query": function(newValue) {
      this.loadItems();
    },
    searchParams(newVal) {
      this.$router
        .replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            search: JSON.stringify(newVal),
            page: 1,
          },
        })
        .catch((err) => {});
    },
  },
  methods: {
    numberFormat(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    convertToEnglishNumber(input) {
      var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
      input = "" + input + "";
      return input.replace(/[\u06F0-\u06F9]/g, function(m) {
        return persianDigits.indexOf(m);
      });
    },
    onCreate(data) {
      let objectKey = Object.keys(data.data)[0];
      if (this.hasPagination) {
        this.items.data.unshift(data.data[objectKey]);
      } else {
        this.items.unshift(data.data[objectKey]);
      }
    },
    onEdit(data, index) {
      let objectKey = Object.keys(data.data)[0];
      let model = data.data[objectKey];
      if (this.hasPagination) {
        Vue.set(this.items.data, index, model);
      } else {
        Vue.set(this.items, index, model);
      }
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            const url = this.url.includes("?")
              ? this.url.substr(0, this.url.indexOf("?"))
              : this.url;
            this.$axios
              .delete(url + `/${id}`)
              .then((response) => {
                if (Array.isArray(this.items)) {
                  this.items = this.items.filter((item) => item.id != id);
                } else {
                  this.items.data = this.items.data.filter(
                    (item) => item.id != id
                  );
                }
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
    createItem() {
      if (this.mode == "edit") {
        this.edit = this.$options.data.apply(this).edit;
      }
      this.mode = "create";
      this.$root.$emit("bv::show::modal", "modal-" + this.$options.name);
    },
    editItem(index) {
      if (!(this.edit && this.edit._index === index) || this.mode !== "edit") {
        let item;
        if (this.hasPagination) {
          item = window.clone(this.items.data[index]);
        } else {
          item = window.clone(this.items[index]);
        }
        item._index = index;
        this.edit = item;
        this.mode = "edit";
      }

      this.$root.$emit("bv::show::modal", "modal-" + this.$options.name);
    },
    showItem(item) {
      this.edit = item;
      this.$root.$emit("bv::show::modal", "modal-show-" + this.$options.name);
    },
    changePage(page) {
      if (this.disabled) {
        return;
      }
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: page },
      });
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        if (typeof this.url !== "string") {
          return;
        }
        let page = this.$route.query.page || 1;
        this.show = true;
        this.disabled = true;
        let search = {};
        if (this.$route.query.search) {
          search = JSON.parse(this.$route.query.search);
        }
        
        let query = "";
          if (this.extraQuery) {
            for (const key in this.extraQuery) {
              query = query + `${key}=${this.extraQuery[key]}&`;
            }
          }
          this.$axios
            .get(this.url+`${this.extraQuery!=null?'?'+query.slice(0, -1):''}`, {
              params: {
                page,
                widgets: !this.widgetsAreLoaded
                  ? JSON.stringify(this.widgets)
                  : "",
                ...search,
              },
            })
            .then((response) => {
              this.widgetsAreLoaded = true;
              let objectKey = Object.keys(response.data.data)[0];
              this.items = response.data.data[objectKey];
              if (response.data.data.widgets) {
                this.widgetData = response.data.data.widgets;
              }
              this.show = false;
              this.disabled = false;
              if (response.data.data.store_rules) {
                this.createRules = response.data.data.store_rules;
              }
              if (response.data.data.update_rules) {
                this.editRules = response.data.data.update_rules;
              }

              this.onLoadHandlers.forEach((f) => {
                f();
              });
              resolve(response);
            })
            .catch((error) => {
              this.$root.notify(error);
              reject(error);
            });
        
      });
    },
    resetFilter() {
      this.filter = this.$options.data.apply(this).filter;
      this.filter.product_id = product.id
      this.filter.variety_id = variety.id
    },
    hasFilter() {
      // اون دیتایی که برای اولین بار ست کردیم
      // اگر فیلتر الان با ابتدا فرق داشت یعنی فیلتر داریم
      let initialFilter = this.$options.data.apply(this).filter;
      let has = false;
      for (let [index, value] of Object.entries(initialFilter)) {
        if (initialFilter[index] !== this.filter[index]) {
          has = true;
          break;
        }
      }
      return has;
    },
  },
};
