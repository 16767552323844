<template>
  <main class="mt-5 admin-slider">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">گروه های اسلایدر</h5>
      </div>
      <div class="card-body row">
        <template v-if="groups !== null">
          <router-link  :to="{name: 'slider' , params: {group: group}}" class="col-md-3 slider-group text-center ml-4" v-for="(group, index) in groups" :key="index">
            <h5 class="d-block my-3 font-bold">{{ groupLabels[index] ? groupLabels[index] : groups }}</h5>
          </router-link>
        </template>
        <div v-else  class="d-flex justify-content-center w-100">
          <Loading/>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SliderGroupImpl from '@@/core/pages/slider/SliderGroup/SliderGroupImpl'
export default {
  extends: SliderGroupImpl
}
</script>

<style scoped src="@@/core/pages/slider/SliderGroup/SliderGroupStyle.css"></style>
