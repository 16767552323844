<template>
  <main class="mt-5">
        <span id="add-btn">
            <button :disabled="hasPermission('write_seller') ? false : true" @click="createItem"
                    class="btn btn-success">فروشنده جدید</button>
        </span>
    <b-tooltip v-if="!hasPermission('write_seller')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <FormModal :edit-model="edit" :mode="mode" @create="onCreate" @edit="onEdit" :url="url" :modal-name="$options.name"
               :create-rules="createRules" :edit-rules="editRules"></FormModal>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> فروشنده ها</h5>
      </div>
      <div class="card-body">
        <b-table responsive hover bordered :items="items.data" :fields="fields">
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate}}
          </template>
          <template v-slot:cell(active)="data">
            <span v-if="data.item.active == 1 " class="badge badge-success status">فعال</span>
            <span v-else-if="data.item.active == 0" class="badge badge-danger status">غیرفعال</span>
          </template>
          <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a :disabled="!hasPermission('modify_seller') ? true : false " class="action-btns1  pt-1 px-2"
                   @click="editItem(data.index)">
                  <i class="feather feather-edit  text-primary"></i>
                </a>
              </span>
            <b-tooltip v-if="!hasPermission('modify_seller')" :target="'edit-btn-' + data.item.id" triggers="hover">
              شما به این بخش دسترسی ندارید
            </b-tooltip>
          </template>
          <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_seller') ? true : false " class="action-btns1  pt-1 px-2 "
                   @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
            <b-tooltip v-if="!hasPermission('delete_seller')" :target="'delete-btn-' + data.item.id" triggers="hover">
              شما به این بخش دسترسی ندارید
            </b-tooltip>
          </template>
        </b-table>
        <Loading v-if="$root.isEmptyObject(items)" />
        <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
                    @pagination-change-page="changePage">
        </pagination>
        <div v-else class="alert alert-danger">
          موردی یافت نشد
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import sellerImpl from '@@/core/pages/seller/Seller/sellerImpl'

export default {
  extends: sellerImpl
}
</script>

<style scoped>

</style>
