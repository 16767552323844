<template>
  <main class="mt-5 shipping">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">ورود اکسل</h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="submit">
          <h5 class="font-weight-bold">ورودی جدید</h5>
          <b-form-file placeholder="فایلی انتخاب نشده" name="file" />
          <button :disabled="disabled" class="btn btn-primary mt-3">ثبت</button>
        </form>
        <div class="w-50 mb-4 mt-5 d-flex ">
          <b-button
            v-if="Object.keys(checkList).length > 0"
            @click="deleteList()"
            variant="outline-danger"
            >حذف گروهی</b-button
          >
          <form id="searchInExcel" autocomplete="off">
            <input
              type="search"
              placeholder="جستوجو کنید"
              autocomplete="off"
              class="form-control"
              v-model="search"
            />
          </form>
        </div>
        <div>
          <b-table
            :filter-included-fields="['receiver_name']"
            :filter="search"
            :fields="fields"
            :items="items"
          >
            <template v-slot:cell(checked)="data">
              <b-form-checkbox v-model="checkList[data.item.id]">
              </b-form-checkbox>
            </template>
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(price)="data">
              {{ data.item.price | price }}
            </template>
            <template v-slot:cell(delete)="data">
              <span style="cursor: pointer" :id="'delete-btn-' + data.item.id">
                <a
                  class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
            </template>
          </b-table>
          <b-button variant="info" v-if="items!=null&&lastIndex<helpItems.length" @click="showMore()">مشاهده بیشتر</b-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  BFormFile,
  BFormInput,
  BTable,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";

export default {
  name: "ShippingExcels",
  components: {
    BFormFile,
    BFormInput,
    BFormCheckbox,
    BTable,
    BButton,
  },
  data() {
    return {
      checkList: {},
      lastIndex: 20,
      helpItems: null,
      disabled: false,
      fields: [
        {
          key: "checked",
          label: "انتخاب",
        },
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "barcode",
          label: "بارکد",
        },
        {
          key: "register_date",
          label: "تاریخ ثبت",
        },
        {
          key: "destination",
          label: "مقصد",
        },
        {
          key: "receiver_name",
          label: "نام گیرنده",
        },
        {
          key: "sender_name",
          label: "نام فرستنده",
        },
        {
          key: "price",
          label: "هزینه کل",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      search: "",
      items: [],
    };
  },
  mounted() {
    this.loadPostTrackings();
  },
  methods: {
    showMore() {
      if (this.lastIndex + 20 <= this.helpItems.length) {
        this.lastIndex += 20;
        this.items = this.helpItems.slice(0, this.lastIndex);
      } else {
        this.lastIndex = this.helpItems.length;
        this.items = this.helpItems.slice(0, this.lastIndex);
      }
    },
    deleteList() {
      let res = "";
      console.log(this.checkList);
      for (const key in this.checkList) {
        if (this.checkList[key] == true) {
          res += `${key},`;
        }
      }
      if (res != "") {
        res = res.slice(0, -1);
        this.$axios
          .delete(`admin/shipping_excels/multiple-delete?ids=${res}`)
          .then(() => {
            alert("درخواست شما با موفقیت انجام شد");
            window.location.reload();
          });
      }
    },
    async loadPostTrackings() {
      try {
        const response = await this.$axios.get("front/shipping_excels");
        this.helpItems = response.data.data.shipping_excels;
        this.items = this.helpItems.slice(0, this.lastIndex);
      } catch (e) {
        alert("مشکلی رخ داد");
      }
    },
    async submit(e) {
      const formData = new FormData(e.target);
      try {
        this.disabled = true;
        const response = await this.$axios.post(
          "admin/shipping_excels",
          formData
        );
        window.location.reload();
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.realDeleteItem(id);
          }
        })
        .catch(() => {});
    },
    async realDeleteItem(id) {
      try {
        const response = await this.$axios.post(`admin/shipping_excels/${id}`, {
          _method: "delete",
        });
        this.items = this.items.filter((i) => i.id != id);
        this.$root.notify("با موفقیت حذف شد", "success");
      } catch (e) {
        this.$root.notify(e);
      }
    },
  },
};
</script>

<style scoped>
#searchInExcel {
  width: 70%;
}
#searchInExcel input {
  width: 100%;
}
</style>
