<template>
  <b-form @submit.prevent="submit">
    <div class="row">
      <b-form-group class="col-md-6" label="نام " label-for="first-name">
        <b-form-input
          :disabled="disabled"
          id="first-name"
          name="first_name"
          v-model="customer.first_name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        class="col-md-6"
        label="نام خانوادگی "
        label-for="last-name"
      >
        <b-form-input
          :disabled="disabled"
          id="last-name"
          name="last_name"
          v-model="customer.last_name"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6">
        <label for="mobile"> شماره همراه <i class="text-danger">*</i></label>
        <b-form-input
          :disabled="disabled"
          id="mobile"
          name="mobile"
          v-model="customer.mobile"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6">
        <label for="password"> کلمه عبور</label>
        <b-form-input
          type="password"
          :disabled="disabled"
          id="password"
          name="password"
          v-model="customer.password"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label="ایمیل" label-for="email">
        <b-form-input
          :disabled="disabled"
          id="email"
          name="email"
          v-model="customer.email"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label-for="code">
        <label for="code"> کد ملی <i class="text-danger">*</i></label>
        <b-form-input
          :disabled="disabled"
          id="code"
          name="national_code"
          v-model="customer.national_code"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label="جنسیت " label-for="gender">
        <multiselect
          v-model="customer.gender"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="items"
          label="name"
          track-by="value"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group class="col-md-6" label="شماره کارت" label-for="card-number">
        <b-form-input
          :disabled="disabled"
          id="card-number"
          name="card_number"
          v-model="customer.card_number"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label="تاریخ تولد " label-for="birth-date">
        <date-picker
          v-model="customer.birth_date"
          name="birth_date"
          format="jYYYY-jMM-jDD"
        />
      </b-form-group>
      <div class="col-md-6 row mt-5">
        <div class="col-md-3  mt-2">
          <b-form-checkbox
            id="digipay"
            :disabled="disabled"
            v-model="customer.digipay"
            value="1"
            unchecked-value="0"
          >
            <span class="pr-5">نمایش درگاه دیجی پی</span>
          </b-form-checkbox>
        </div>
        <div class="col-md-3  mt-2">
          <b-form-checkbox
            id="newsletter"
            :disabled="disabled"
            v-model="customer.newsletter"
            value="1"
            unchecked-value="0"
          >
            <span class="pr-5">خبرنامه</span>
          </b-form-checkbox>
        </div>
        <div class="col-md-3 mt-2">
          <b-form-checkbox
            id="foreign-national"
            :disabled="disabled"
            v-model="customer.foreign_national"
            value="1"
            unchecked-value="0"
          >
            <span class="pr-5"> تبعه خارجی</span>
          </b-form-checkbox>
        </div>
        <div v-if="customer && customer.status !== null" class="col-md-3 mt-2">
          <b-form-checkbox
            id="status"
            :disabled="disabled"
            name="status"
            v-model="customer.status"
            value="1"
            unchecked-value="0"
          >
            <span class="pr-5">وضعیت</span>
          </b-form-checkbox>
        </div>
      </div>
      <div v-if="customerRoles" class="col-md-6 text-right mt-5">
        <label>انتخاب نقش مشتری (اختیاری)</label>
        <multiselect
          v-model="selectedCustomerRole"
          placeholder="انتخاب کنید"
          :allow-empty="true"
          open-direction="bottom"
          :options="customerRoles"
          label="name"
          track-by="value"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </div>
      <div class="col-md-12 text-center mt-5">
        <button :disabled="disabled" type="submit" class="btn btn-success">
          {{ mode == "edit" ? " ویرایش مشتری" : " ثبت مشتری" }}
        </button>
      </div>
    </div>
  </b-form>
</template>
<script>
import CustomerFormImpl from "@@/core/components/customer/CustomerForm/CustomerFormImpl";

export default {
  extends: CustomerFormImpl,
};
</script>
<style
  scoped
  src="@@/core/components/customer/CustomerForm/CustomerFormStyle.css"
></style>
