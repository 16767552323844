import {BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BModal, BFormCheckbox, BForm} from "bootstrap-vue";
import configProvider from "@@/core/configProvider";

export default {
    components: {
        BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BModal, BFormCheckbox, BForm
    },
    props: {
        mode: {
            default: 'create',
            type: String
        },
        url: String,
        customer: Object,
        redirect: {
            default: true,
            type: Boolean
        },
        isDisabled: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            disabled: this.isDisabled,
            items: [{
                    value: 'male',
                    name: 'مرد'
                },
                {
                    value: 'female',
                    name: 'زن'
                },
            ],
            btnDisabled: false,
            customerRoles: null,
            selectedCustomerRole: null,
        }
    },
    watch: {
        customer() {
            const i = setInterval(() => {
                if (configProvider.get('customer.hasRole') && this.customerRoles) {
                    this.selectedCustomerRole = this.customerRoles.find(c => c.id == this.customer.role_id)
                    clearInterval(i)
                }
            }, 100)
        }
    },
    mounted() {
        if (configProvider.get('customer.hasRole')) {
            this.loadCreate()
        }
    },
    methods: {
        async loadCreate() {
            const response = await this.$axios.get('admin/widgets?widgets=["create_customer"]');
            this.customerRoles = response.data.data.widgets.customer_roles.map(cR => {
                cR.value = cR.id

                return cR
            })
        },
        setDisabled(val) {
            this.disabled = val
        },
        async submit(e) {
            e.preventDefault()
            this.disabled = true

            try {
                let formData = new FormData(e.target);
                formData.append('gender',  this.customer.gender ? this.customer.gender.value : '');
                formData.append('newsletter',  this.customer.newsletter == 1 ? 1 : 0);
                formData.append('digipay',  this.customer.digipay == 1 ? 1 : 0);
                formData.append('foreign_national',  this.customer.foreign_national == 1 ? 1 : 0);
                formData.append('status' , this.customer.status == 1 ? 1 : 0);
                formData.append('_method' , this.mode == 'edit' ? 'put' : 'post');
                configProvider.get('customer.hasRole') &&
                formData.append('role_id', this.selectedCustomerRole ? this.selectedCustomerRole.value : '');
                const response = await this.$axios.post(this.url, formData);
                this.$root.notify(response, 'success');
                if (this.redirect) {
                    this.$router.push('/admin/customers');
                } else {
                    this.$emit('create', response.data.data.customer)
                }
            } catch (error) {
                this.$root.notify(error)
            }
            this.disabled = false
        },
    }
}
