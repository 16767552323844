<template>
    <main class="mt-5">
        <span id="add-btn">
            <button :disabled="hasPermission('write_coupon') ? false : true" @click="newCoupon"
                v-b-modal.coupon-modal class="btn btn-success">کد تخفیف جدید</button>
        </span>
        <b-tooltip v-if="!hasPermission('write_coupon')" target="add-btn" triggers="hover">
            شما به این بخش دسترسی ندارید
        </b-tooltip>
        <div class="card mt-3">
            <div class="card-header  border-0 d-flex justify-content-between">
                <h5 class="card-title font-bold"> کد تخفیف ها</h5>
                <form autoCompelete="off"  class="col-6">
                <b-form-input v-model="search" placeholder="عنوان کد تخفیف را وارد کنید"></b-form-input>
                
                </form>
            </div>

            <div class="card-body">
                <div>
                    <b-table responsive hover bordered :items="items.data" :fields="fields">
                        <template v-slot:cell(start_date)="data">
                            {{ data.item.start_date | persianDate}}
                        </template>
                        <template v-slot:cell(end_date)="data">
                            {{ data.item.end_date | persianDate}}
                        </template>
                        <template v-slot:cell(created_at)="data">
                            {{ data.item.created_at | persianDate}}
                        </template>
                        <template v-slot:cell(edit)="data">
                            <span :id="'edit-btn-' + data.item.id">
                                <a @click="editItem(data.item.id,data.index)" :disabled="disabled || !hasPermission('modify_coupon') ? true : false "
                                class="action-btns1  pt-1 px-2">
                                <i class="feather feather-edit  text-primary"></i>
                            </a>
                            </span>
                            <b-tooltip v-if="!hasPermission('modify_coupon')" :target="'edit-btn-' + data.item.id"
                                triggers="hover">
                                شما به این بخش دسترسی ندارید
                            </b-tooltip>
                        </template>
                        <template v-slot:cell(delete)="data">
                            <span :id="'delete-btn-' + data.item.id">
                                <a :disabled="!hasPermission('delete_coupon') ? true : false "  class="action-btns1  pt-1 px-2 " @click="deleteItem(data.item.id)">
                                    <i class="feather feather-trash-2 text-danger"></i>
                                </a>
                              </span>
                              <b-tooltip v-if="!hasPermission('delete_coupon')" :target="'delete-btn-' + data.item.id" triggers="hover">
                                شما به این بخش دسترسی ندارید
                              </b-tooltip>
                        </template>
                    </b-table>
                    <Loading v-if="$root.isEmptyObject(items)" />
                    <pagination v-if="items.data != ''" :limit="2" :disabled="disabled" :data="items"
                        @pagination-change-page="changePage">
                    </pagination>
                    <div v-else-if="items.data == ''" class="alert alert-danger">
                        موردی یافت نشد
                    </div>
                </div>
            </div>
        </div>
        <CouponModal :mode="mode" :coupon="coupon" :url="submit_url" />
    </main>
</template>
<script>
import CouponImpl from '@@/core/pages/Coupon/CouponImpl'
export default {
    extends: CouponImpl
}
</script>