import ImageHolder from "@@/core/components/shared/ImageHolder/ImageHolder";
import Input from "@@/core/libraries/form/components/Input";
import customFields from "@@/core/libraries/customFields";
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  name: "SliderModal",
  components: {
    ImageHolder,
    Input,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
  },
  props: {
    linkables: Array,
    group: String,
    mode: {
      default: "create",
      type: String,
    },
    value: Object,
    labelMultiselect: String,
  },
  data() {
    return {
      disabled: false,
      searchItem: "",
      helpVar: "",
      saerchLoading: false,
      searchedlist: [],
      url: "",
      models: [],
      modelLabel: this.labelMultiselect,
      linkUrl: false,
      slider: this.value,
      customFieldsValues: Array(customFields.sliders.length).fill(""),
    };
  },
  watch: {
    searchItem(newValue, oldVAlue) {
      this.helpVar = newValue;
      if (newValue != "")
        setTimeout(() => {
          if (!this.helpVar[newValue.length]) {
            this.searchItems(newValue);
          }
        }, 1000);
    },
    value(newVal) {
      this.slider = newVal;
      this.fillCustomFields();
    },
    slider(newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    // this.fillCustomFields();
  },
  methods: {
    searchItems(newValue) {
      this.saerchLoading = true;
      this.$axios
        .get(this.slider.linkable_type.api + newValue)
        .then((res) => {
          this.saerchLoading = false;
          this.searchedlist = res.data.data[this.slider.linkable_type.key];
          if (this.searchedlist.length) {
            this.modelLabel = this.searchedlist[0].title ? "title" : "name";
          }
        })
        .catch((error) => {
          this.saerchLoading = false;
        });
    },
    fillCustomFields() {
      var customFieldsFromValue = {};
      if (this.slider.custom_fields)
        customFieldsFromValue = JSON.parse(this.slider.custom_fields);
      if (customFields.sliders && customFields.sliders.length) {
        customFields.sliders.forEach((slider, index) => {
          if (customFieldsFromValue[slider.name])
            this.customFieldsValues[index] = customFieldsFromValue[slider.name];
        });
      }
    },
    async submit(e) {
      e.preventDefault();
      console.log(this.slider);
      this.disabled = true;
      try {
        this.url =
          this.mode == "edit"
            ? `admin/sliders/${this.slider.id}`
            : "admin/sliders";
        let formData = new FormData(this.$refs.form);
        this.mode == "edit" ? formData.append("_method", "PUT") : "";
        formData.append("linkable_type", this.slider.linkable_type.model);
        formData.append("status", this.slider.status == 1 ? 1 : 0);
        formData.append("group", this.group);
        let finalCustomFields = {};
        if (customFields.sliders && customFields.sliders.length) {
          customFields.sliders.forEach((slider, index) => {
            finalCustomFields[slider.name] = this.customFieldsValues[index];
          });
        }
        if (Object.entries(finalCustomFields).length) {
          formData.append("custom_fields", JSON.stringify(finalCustomFields));
        }
        const response = await this.$axios.post(this.url, formData);

        this.$root.notify(response.data.message, "success");
        if (this.mode == "edit") {
          this.$root.$set(
            this.$parent._data.sliders,
            this.slider.index,
            response.data.data.slider
          );
        } else {
          this.$parent._data.sliders.push(response.data.data.slider);
        }
        this.$root.$emit("bv::hide::modal", "slider-modal");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    // loadModels(item) {
    //   this.slider.linkable_id = null;
    //   // if (item) {
    //   //   this.models = item.models;
    //   //   if (this.models != null && this.models != "" && this.models[0].title) {
    //   //     this.modelLabel = "title";
    //   //     this.linkUrl = false;
    //   //   } else if (
    //   //     this.models != null &&
    //   //     this.models != "" &&
    //   //     this.models[0].name
    //   //   ) {
    //   //     this.modelLabel = "name";
    //   //     this.linkUrl = false;
    //   //   } else if (this.models != null && this.models == "link_url") {
    //   //     this.linkUrl = true;
    //   //   }
    //   //   if (this.models == null) {
    //   //     this.linkUrl = false;
    //   //   }
    //   // }
    // },
  },
  computed: {
    customFields() {
      return customFields.sliders;
    },
  },
};
