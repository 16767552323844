<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">ثبت خرید</h5>
        <div style="flex:1"></div>
        <Back url="/admin/shop" />
      </div>
      <div class="card-body">
        <form @submit.prevent="submit">
          <b-form-group label="انتخاب مشتری">
            <div class="d-flex col-md-6 mb-3" style="gap: 0.5rem">
              <b-form-input @keypress.enter.prevent="requestCustomer"
                            v-model="customerMobile"
                            :disabled="disabled || customerInfo" @input="requestCustomer" type="text" />
              <button @click="clearCustomer" v-if="customerInfo" class="btn btn-danger">لغو مشتری</button>
            </div>
            <template v-if="customerInfo">
              <div v-if="customerInfo.customer" class="row">
                <div class="col-4 px-3">
                  <div  class="little card info">
                    <div class="card-header">اطلاعات کاربری</div>
                    <div class="card-body">
                      <div class="d-flex" style="gap: 1rem">
                        <div v-if="customerInfo.customer.full_name">نام و نام خانوادگی: {{ customerInfo.customer.full_name }}</div>
                        <div>شارژ کیف پول: {{ customerInfo.customer.wallet.balance }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="i in [['آنلاین', 'online'], ['حضوری', 'physical']]" :key="i[1]" class="col-4 px-3">
                  <div  class="little card " :class="i[1]">
                    <div class="card-header">آمار {{ i[0] }}</div>
                    <div class="card-body">
                      <div class="d-flex" style="gap: 1rem">
                        <div>تعداد خرید: {{ customerInfo[i[1]].orders_count }}</div>
                        <div>میزان خرید: {{ customerInfo[i[1]].orders_total }}</div>
                        <div  v-if="customerInfo[i[1]].last_order_at">آخرین خرید: {{customerInfo[i[1]].last_order_at | persianDate }}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 font-weight-bold" v-else>مشتری جدید</div>
            </template>

          </b-form-group>
          <b-form-group label="انتخاب محصول">
            <div class="d-flex col-md-6" style="gap: 0.5rem">
              <b-form-input ref="productSku" v-model="productSku" :disabled="disabled" @keypress.prevent.enter="requestProduct" type="text" />
              <div class="d-flex mr-3" style="gap: 0.6rem">
                <label for="refund_checkbox">مرجوعی</label>
                <b-form-checkbox id="refund_checkbox" type="checkbox" :disabled="disabled" v-model="refund" />
              </div>
            </div>
          </b-form-group>
          <div v-for="i in ['varieties', 'refund_varieties']" v-if="$data[i] != ''">
            <template>
              <h4 class="table-title">
                <template v-if="i === 'varieties'">فروش</template>
                <template v-else>مرجوع</template>
              </h4>
            </template>
            <b-table
                class="mt-3 admin-order" :class="$data[i]"
                hover
                bordered
                responsive
                :items="$data[i]"
                :fields="fields"
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template v-slot:cell(image)="data" class="text-center">
                <div class="order-product-img">
                  <a
                      :href="getImage(data.item)"
                  >
                    <img
                        class="w-100 h-100"
                        :src="getImage(data.item)"
                    />
                  </a>
                </div>
              </template>
              <template v-slot:cell(title)="data">
            <span style="width:300px">
                                      <span style="line-height:2">{{ data.item.product.title }}</span>
                        <span
                            v-if="data.item.color || data.item.attributes != ''">
                          <span>
                            |
                          </span>
                  <span v-if="data.item.color">
                    رنگ : {{ data.item.color.name }}
                    {{ data.item.attributes != "" ? " | " : "" }}
                  </span>
                  <span v-if="data.item.attributes != ''">
                    <span
                        v-for="(attr, index) in data.item.attributes"
                        :key="index"
                    >
                      {{ attr.label + ": " + attr.pivot.value }}
                      {{
                        index != data.item.attributes.length - 1
                            ? " | "
                            : ""
                      }}
                    </span>
                  </span>
                          </span>
            </span>
              </template>
              <template v-slot:cell(quantity)="data">
                <button
                    type="button"
                    class="border border-radius7 px-1 bg-none"
                    @click="decrement(data.item)"
                    :disabled="data.item.quantity == 1 || disabled ? true : false"
                >
                  <DashSvg />
                </button>
                <span class="px-2">{{ data.item.quantity }}</span>
                <button
                    type="button"
                    class="border border-radius7 px-1 bg-none"
                    @click="increment(data.item)"
                    :disabled="disabled"
                >
                  <PlusSvg />
                </button>
              </template>
              <template v-slot:cell(amount)="data">
                <b-form-input style="width: 80px" class="m-auto" :value="data.item.final_price.amount | priceinput"
                              @input="value=>{data.item.final_price.amount = value !== '' ? +value.replace(/\D/g, '') : ''}">
                </b-form-input>
              </template>
              <template v-slot:cell(discount_amount)="data">
            <span v-if="data.item.final_price.discount_price">{{
                (data.item.final_price.discount_price) | price
              }}</span>
                <span v-else>ندارد</span>
              </template>
              <template v-slot:cell(flash)="data">
                {{ data.item.product && data.item.product.active_flash != '' ? data.item.product.active_flash[0].title : "ندارد" }}
              </template>
              <template v-slot:cell(total_price)="data">
            <span v-if="data.item.final_price.amount != 0">{{
                (data.item.final_price.amount * data.item.quantity) | price
              }}</span>
                <span v-else>0</span>
              </template>
              <template v-slot:cell(delete)="data">
                <a class="action-btns1  pt-1 px-2 " @click="deleteItem(data.item, i)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </template>
            </b-table>
          </div>
          <template v-if="varieties != '' || refund_varieties != ''">
            <div class="mt-5 col-md-4 mx-auto card justify-content-center little py-5 d-flex">
              <div class="d-md-flex justify-content-between">
                <span class=" px-3 py-2 font-bold">
                  مجموع قیمت کالا ها :
                </span>
                <span class=" px-3 py-2"> {{ total | price }} تومان </span>
              </div>
              <div class="d-md-flex justify-content-between">
                <span class=" px-3 py-2 font-bold">
                  تخفیف :
                </span>
                <span class=" px-3 py-2">
                  <b-form-input style="width: 80px" :value="discountAmount | priceinput"
                                @input="value=>{discountAmount = value !== '' ? +value.replace(/\D/g, '') : ''}" />
                </span>
              </div>
              <div v-if="customerInfo && customerInfo.customer && (customerInfo.customer.wallet.balance > 0)" class="d-md-flex justify-content-between">
                <span class=" px-3 py-2 font-bold">
                  میزان کسر از کیف پول :
                </span>
                <span class=" px-3 py-2">
                  <b-form-input style="width: 80px" :value="customerInfo.from_wallet_amount | priceinput"
                                @input="value=>{customerInfo.from_wallet_amount = value !== '' ? +value.replace(/\D/g, '') : ''}" />
                </span>
              </div>
              <div class="d-md-flex justify-content-between">
                <span class=" px-3 py-2 font-bold">
                  پرداخت نهایی :
                </span>
                <span class=" px-3 py-2"
                      :class="{'text-success': totalPrice >= 0, 'text-danger': totalPrice < 0}"> {{ totalPrice | price }} تومان </span>
              </div>
            </div>
            <div class="row mt-4">
              <b-form-group  class="col-md-6" label="کد رهگیری">
                <b-form-input placeholder="کد رهگیری" />
              </b-form-group>
              <b-form-group  class="col-md-6" label="یادداشت">
                <b-form-textarea placeholder="یادداشت" />
              </b-form-group>
            </div>
            <div class="mt-4 w-100 d-flex justify-content-center">
              <button type="submit" :disabled="disabled" class="btn btn-success">ثبت سفارش</button>
            </div>
          </template>

        </form>
      </div>
    </div>
  </main>
</template>

<script>
import {BFormGroup, BFormInput, BTable, BFormCheckbox, BFormTextarea} from "bootstrap-vue";
import PlusSvg from "@@/core/components/svgs/PlusSvg";
import DashSvg from "@@/core/components/svgs/DashSvg";
import Back from '@@/core/components/shared/Back/Back'

export default {
  name: "ShopCreate",
  components: {DashSvg, PlusSvg, BFormGroup, BFormInput, BTable, BFormCheckbox, BFormTextarea, Back},
  data() {
    return {
      disabled: false,
      refund: false,
      discountAmount: 0,
      productSku: '',
      customerMobile: '',
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "barcode",
          label: "بارکد",
        },
        {
          key: "image",
          label: "تصویر",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "amount",
          label: "قیمت واحد (تومان)",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        // {
        //   key: "discount_amount",
        //   label: "تخفیف واحد (تومان)",
        // },
        {
          key: "flash",
          label: "کمپین",
        },
        {
          key: "total_price",
          label: "قیمت نهایی (تومان)",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      varieties: [],
      refund_varieties: [],
      customerInfo: null,
    }
  },
  computed: {
    total() {
      return this.varieties.reduce((a, b) => a + (b.final_price.amount * b.quantity), 0)
      - this.refund_varieties.reduce((a, b) => a + (b.final_price.amount * b.quantity), 0)
    },
    totalPrice() {
      return this.total - this.discountAmount - (this.customerInfo ? this.customerInfo.from_wallet_amount : 0)
    }
  },
  mounted() {
    this.listenForKeyPress()
  },
  methods: {
    listenForKeyPress() {
      // add document event listener for all key presses
      document.removeEventListener('keyup', this.onKeyPress)
      document.addEventListener('keyup', this.onKeyPress)
    },
    onKeyPress(e) {
      const input = this.$refs.productSku
      if (/[0-9]/g.test(e.key)) {
        if (document.activeElement.tagName !== 'INPUT') {
          input.focus();
          this.productSku = e.key;
        }
      }
    },
    async requestProduct() {
      try {
        this.disabled = true
        const response = await this.$axios.get('admin/mini_orders/search', {
          params: {
            sku: this.productSku,
            refund: this.refund
          }
        })
        const variety = response.data.data.variety
        this.addVariety(variety)
        this.productSku = ''
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false
      }
    },
    addVariety(variety) {
      const mode = this.refund ? 'refund_varieties' : 'varieties'
      const alreadyAdded = this[mode].find(v => {
        return v.id == variety.id
      })
      if (alreadyAdded) {
        alreadyAdded.quantity++;
        this.$root.notify('به تعداد محصول اضافه شد: ' + alreadyAdded.quantity, 'success')
      } else {
        variety.quantity = 1
        this[mode].push(variety);
      }
    },
    getImage(variety) {
      if (variety.images != '') {
        return variety.images[0].url
      }
      if (variety.product.images != '') {
        return variety.product.images[0].url
      }
      return null
    },
    decrement(variety) {
      if (variety.quantity === 0) {
        return
      }
      variety.quantity--;
    },
    increment(variety) {
      variety.quantity++;
    },
    deleteItem(variety, mode) {
      this[mode] = this[mode].filter(v => {
        return v.id !== variety.id
      })
    },
    async requestCustomer(mobile) {
      if (mobile.length !== 11) return;
      try {
        this.disabled = true
        const response = await this.$axios.get('admin/mini_orders/customer-search', {
          params: {
            mobile
          }
        })
        this.disabled = false
        const data = response.data.data;
        data.from_wallet_amount = data.from_wallet_amount || 0;
        this.customerInfo = data
        this.productSku = ''
      } catch (e) {
        this.$root.notify(e)
      }
    },
    clearCustomer() {
      this.customerInfo = null
      this.customerMobile = ''
    },
    async submit() {
      if (!this.validate()) {
        return
      }
      const varieties = this.varieties.map(v => {
        return {
          id: v.id,
          quantity: v.quantity,
          amount: v.final_price.amount
        }
      })
      const refund_varieties = this.refund_varieties.map(v => {
        return {
          id: v.id,
          quantity: v.quantity,
          amount: v.final_price.amount
        }
      })
      try {
        this.disabled = true
        const response = await this.$axios.post('admin/mini_orders', {
          mobile: this.customerMobile,
          discount_amount: this.discountAmount,
          description: this.description,
          from_wallet_amount: this.from_wallet_amount,
          varieties, refund_varieties
        })

        const miniOrder = response.data.data.mini_order
        this.$root.notify(response.data.message, "success");
        this.$router.push(`/admin/shop/orders/${miniOrder.id}?print=1`)
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false
      }
    },
    validate() {
      if ((!this.varieties || !this.varieties.length) && ((!this.refund_varieties && !this.refund_varieties.length))) {
        this.$root.warningNotify('حداقل انتخاب یک تنوع الزامی است')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
.order-product-img {
  max-width: 50px;
  margin: auto;
}

.table-title {
  font-weight: bold;
}
</style>
<style>
.card.little.info {
  background: linear-gradient(30deg, #d2e8ff 0%, #d5e6fc 35%, #eaf5ff 100%);
}

.card.little.online {
  background: linear-gradient(30deg, #c8ffc8 0%, #c8ffc8 35%, #e0ffe0 100%);
}

.card.little.physical {
  background: linear-gradient(30deg, #ffffe3 0%, #ffffbc 35%, #ffffc5 100%);
}

.card.little .card-header {
  font-weight: bold;
}

</style>
